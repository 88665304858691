import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const PrimaryButton = ({ to, text, url }) => {

  const style = {
    backgroundColor: `#fff`,
    borderColor: `#fff`,
    color: `#d23464`,
    textTransform: `uppercase`,
    borderRadius: `0`,
    padding: `.6rem 1rem`,
    fontWeight: `600`
  }

  if(url) {
    return <OutboundLink href={url} className="btn btn-primary" style={style} target="_blank" rel="noopener noreferrer" onClick={e => { if(window.dataLayer) { window.dataLayer.push({event: 'outbound-link', 'gtm.elementUrl': url}) } }}>{text}</OutboundLink>
  } else {
    return <Link to={to} className="btn btn-primary" style={style}>{text}</Link>
  }

}

PrimaryButton.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
}

PrimaryButton.defaultProps = {
  to: `/`,
  text: `Primary Button`,
}

export default PrimaryButton