import PropTypes from "prop-types"
import React from "react"

import Modal from 'react-modal'

Modal.setAppElement('#___gatsby')

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: '20%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: `0`,
    borderRadius: `0`,
    backgroundColor: `transparent`,
    // height: `500px`,
    // overlfow: `scroll`
  }
};

const AppModal = ({children, openButton, title}) => {

  // var subtitle;
  const [modalIsOpen,setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal(){
    setIsOpen(false);
  }

  return (
    <div>
      <button className="btn btn-primary" style={{
        backgroundColor: `rgb(48, 196, 191)`,
        border: `0`,
        borderRadius: `0`
      }} onClick={openModal}>{openButton}</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={title}
      >
        {/* <h2 ref={_subtitle => (subtitle = _subtitle)}>{title}</h2> */}
        {children}
        <div className="text-right mt-3">
          <button className="btn btn-primary" style={{
            backgroundColor: `#fff`,
            borderColor: `#fff`,
            color: `#d23464`,
            textTransform: `uppercase`,
            borderRadius: `0`,
            padding: `.6rem 1rem`,
            fontWeight: `600`
              }} onClick={closeModal}>Close</button>
        </div>
      </Modal>
    </div>
  )
}

AppModal.propTypes = {
  openButton: PropTypes.string,
}

AppModal.defaultProps = {
  openButton: `Open Modal`,
}

export default AppModal
