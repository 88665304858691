import PropTypes from "prop-types"
import React from "react"

const PageTitle = ({ text }) => (
  <h1 style={{
    color: `#fff`,
    fontFamily: `Avenir`,
    fontWeight: `900`,
    fontSize: `3.5rem`,
    textAlign: `center`,
    margin: `3rem 0`
  }}>{text}</h1>
)

PageTitle.propTypes = {
  text: PropTypes.string,
}

PageTitle.defaultProps = {
  text: `Page Title`,
}

export default PageTitle