import React from "react"
// import { Link } from "gatsby"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import CategoriesHeaderImage from "../components/categories-header"
import PlacesBackgroundImage from "../components/places-background-image"
import PeopleBackgroundImage from "../components/people-background-image"
// import ProcessesBackgroundImage from "../components/processes-background-image"
// import TechnologyBackgroundImage from "../components/technology-background-image"
import BandsCircle from "../components/bands-circle"
import BandsDiamond from "../components/bands-diamond"
import Row from "../components/bootstrap/row"
import AwardCategory from "../components/award-category"
import AppModal from "../components/app-modal"
import PrimaryButton from "../components/primary-button"

const CategoriesPage = () => (
  <Background>
    <Layout>
      <Seo title="Award Categories" />
      <Container>
        <PageTitle text="Award Categories" />
        {/* <div className="text-center mb-5">
          <PrimaryButton to="/shortlist/" text="View the Shortlist" />
        </div> */}
        <div style={{
          position: `relative`
        }}>
          <div style={{
            backgroundColor: `#000000`,
            width: `100%`,
            height: `100%`,
            opacity: `60%`,
            position: `absolute`,
            top: `0`,
            left: `0`,
            zIndex: `1`
          }}></div>
          <CategoriesHeaderImage />
        </div>
        <div style={{
          fontFamily: `Din Pro`,
          fontWeight: `400`,
          fontSize: `1rem`,
          color: `#fff`,
          background: `linear-gradient(90deg, rgba(210,52,100,.9) 0%, rgba(251,146,1,.9) 100%)`
        }}>
          <Container>
            <div className="row" style={{ padding: `2rem`}}>
              <div className="col-sm">
                <h4 style={{
                  fontWeight: `bold`,
                  padding: `1.5rem`
                }}>IN ADDITION TO THE CATEGORY-SPECIFIC CRITERIA, THE JUDGES WILL BE LOOKING FOR THE FOLLOWING FROM ENTRIES:</h4>
                <ul>
                  <li style={{marginBottom: `1rem`}}>Innovation, Imagination and Ambition</li>
                  <li style={{marginBottom: `1rem`}}>Evidence of a clear strategy for the project / policy, clearly communicated to staff and other stakeholders</li>
                  <li style={{marginBottom: `1rem`}}>Evidence of good management</li>
                  <li style={{marginBottom: `1rem`}}>Value for money</li>
                  <li style={{marginBottom: `1rem`}}>Supporting evidence that the project / policy has made a difference to services.</li>
                  <li>Evidence it has achieved or is well on the way to achieving what it set out to do</li>
                </ul>
              </div>
              <div className="col-sm">
                <div style={{
                  border: `1px solid #fff`,
                  padding: `2rem`,
                  paddingBottom: `1rem`
                }}>
                  <ul>
                    <li style={{marginBottom: `1rem`}}>If you are making different entries under several different categories, please indicate this and complete an entry for each. </li>
                    <li style={{marginBottom: `1rem`}}>Each application needs to specifically address the criteria for each individual category.</li>
                    <li style={{marginBottom: `1rem`}}>The same project can be entered in different categories but a separate submission must be sent for each one, geared towards the category it is entered into.</li>
                    <li style={{marginBottom: `1rem`}}>Public sector organisations  can still enter multiple submissions for the same category with different projects, should they so choose.</li>
                    <li style={{marginBottom: `1rem`}}>Entries are scored using the published criteria for each category in addition to the general criteria.</li>
                  </ul>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Container>
      
      <Container>
        <br /><br /><br /><br />
        <Container>
          <div style={{ position: `relative`}}>
            <BandsCircle style={{ position: `absolute`, bottom: `-6rem`, right: `-14rem`, zIndex: `1`}} size="20rem" />    
            <BandsDiamond type="fine" style={{ position: `absolute`, left: `-2.5rem`, top: `-2.5rem`}} />
            <div style={{
              background: `linear-gradient(90deg, rgba(251,146,1,1) 0%, rgba(210,52,100,1) 100%)`,
              width: `10rem`,
              height: `10rem`,
              borderRadius: `50%`,
              position: `absolute`,
              bottom: `7rem`,
              right: `-15rem`,
              zIndex: `-1`
            }} />
            <div className="row">
              <div className="col-md" style={{
                background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                padding: `3rem`,
                position: `relative`
              }}>
                <div style={{ margin: `0 1.1rem`}}>
                  <h4 style={{
                    display: `inline`,
                    backgroundColor: `#d23464`,
                    padding: `.5rem 0`,
                    lineHeight: `3.4rem`,
                    boxShadow: `1.2rem 0 0 #d23464, -1.2rem 0 0 #d23464`,
                    textTransform: `uppercase`,
                    color: `#fff`,
                    fontFamily: `Avenir`,
                    fontWeight: `900`
                  }}>The Categories</h4>
                  <br />
                  <h4 style={{
                    display: `inline`,
                    backgroundColor: `#d23464`,
                    padding: `.5rem 0`,
                    lineHeight: `3.4rem`,
                    boxShadow: `1.2rem 0 0 #d23464, -1.2rem 0 0 #d23464`,
                    textTransform: `uppercase`,
                    color: `#fff`,
                    fontFamily: `Avenir`,
                    fontWeight: `900`
                  }}><small>Enter Your Nominations</small></h4>
                </div>
                <p style={{
                  color: `#fff`,
                  fontFamily: `Din Pro`,
                  marginTop: `1.5rem`
                }}>Below are the main Smarter Working Live award categories, comprising of 14 awards which your organisation or team can submit entries to. Before submitting your entry, ensure you have read the criteria listed under each award.<br /><br />View the category-specific criteria for each award by clicking the 'Read more' button.<br /><br /><strong style={{fontSize: `18px`}} hidden>Deadline for entries is Friday <s>14th June 2024</s> 19th July 2024</strong></p>
                <PrimaryButton to="/entry-guidance/" text="View the Nomination Guidance" />
              </div>
              <PeopleBackgroundImage className="col-md" />
            </div>
          </div>
        </Container>
        <br /><br /><br />
        <Container>
          <Row>
            <div className="col-md p-lg-4">
              <AwardCategory title="Improving Spaces - Creating A Great Place to Work" gridColor="#d23464" icon="computer-desk">
                <AppModal openButton="Read More">
                  <AwardCategory title="Improving Spaces - Creating A Great Place to Work" gridColor="#d23464" icon="computer-desk">
                    <p>Visionary employers are designing spaces to cultivate great workplace experiences. When diverse teams feel that they are valued and belong, they unlock their full potential.</p>
                    <p>This award celebrates ambitious organisations that have reimagined their workspaces to cultivate collaboration and can demonstrate evidence of the positive impacts on staff.</p>

                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Workspaces that have sparked greater collaboration, creativity, or communication among teams and with external stakeholders.</li>
                      <li>Strategic projects that promote wellbeing and encourage healthy mindsets and behaviours in the workplace.</li>
                      <li>Innovative hybrid working models that encourage flexibility and provide opportunities to people who might struggle with traditional office hours.</li>
                      <li>Workspaces that can prove inclusive design that improves accessibility and enhances belonging.</li>
                      <li>Employee-led initiatives where organisations continually listen to and act on feedback from staff.</li>
                      <li>Projects which showcase how a user-centred approach has transformed services and accelerated growth in support of current and future business needs.</li>
                    </ul>

                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
            <div className="col-md p-lg-4">
              <AwardCategory title="Future Focused and Sustainable Property" gridColor="#30c4bf" icon="office">
                <AppModal openButton="Read More">
                  <AwardCategory title="Future Focused and Sustainable Property" gridColor="#30c4bf" icon="office">
                    <p>The physical workspace must keep step with rapidly changing ways of working. It demands imagination and thoughtful planning to ensure spaces are modern, fit-for-purpose and sustainable.</p>
                    <p>This award celebrates pioneering organisations that have designed and built workplaces delivering these three foundations. We invite entries where innovation, functionality and sustainability combine to create inspiring, future-proofed spaces that foster creative thinking. </p>

                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>

                    <ul>
                      <li>Workspaces that are innovative in their design and build, demonstrating exceptional functionality and consideration of environmental and social impact.</li>
                      <li>Workspaces that add value or enhance delivery for staff and customers, such as community shared spaces.</li>
                      <li>Projects where multiple partners and stakeholders have combined expertise and insights to create a future-proofed space.</li>
                      <li>Projects that have embraced innovative technology to create modern, greener places to work.</li>
                      <li>Projects that promote efficient utilisation of property and drive efficiency with clear evidence of the impact and that the project is meeting objectives.</li>
                    </ul>

                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
          </Row>
          <Row>
            <div className="col-md p-lg-4">
              <AwardCategory title="Innovation as a Service" gridColor="#30c4bf" icon="innovation">
                <AppModal openButton="Read More">
                  <AwardCategory title="Innovation as a Service" gridColor="#30c4bf" icon="innovation">
                    <p>With mounting pressures on the public sector, it's essential for leaders to embrace innovation to secure the long-term future of services. Outstanding projects combine a user-centred, collaborative and inclusive approach with a robust focus on outcomes and scalability.</p>
                    <p>This award is for organisations and teams who have truly reimagined services and contributed to the goals of smarter working.</p>
                    <p>Examples of entries could include (but are not limited to *):</p>
                    <ul>
                      <li>Projects that leverage cutting-edge innovations, such as AI, automation and data analytics, to support enhanced service delivery.</li>
                      <li>Projects that foster collaboration and partnerships in the public sector to achieve shared goals.</li>
                      <li>Projects with a broader social impact that promote inclusivity and diversity in the workplace.</li>
                      <li>Projects that have delivered tangible outcomes of improved performance, productivity and user satisfaction.</li>
                      <li>Projects that have been thoughtfully designed to integrate effectively with other services while also producing a model that can be rolled out and adapted elsewhere.</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
            <div className="col-md p-lg-4">
              <AwardCategory title="Harnessing the Value of Data" gridColor="#d23464" icon="binary">
                <AppModal openButton="Read More">
                  <AwardCategory title="Harnessing the Value of Data" gridColor="#d23464" icon="binary">
                    <p>Data has the power to generate valuable insights, shape truly personalised services and unlock efficiencies. While the benefits are clear, public sector organisations must protect the privacy and security of data to maintain trust. Leaders need to leverage the opportunities while minimising the risks of cyber attacks and leaks.</p>
                    <p>This award is for organisations that have demonstrated vision in integrating data insights into decision-making while implementing strategies to protect against risks and ensure the safe and responsible use of data.</p>
                    <p>Examples of entries could include (but are not limited to*):</p>
                    <ul>
                      <li>Projects that can demonstrate how they have drawn on data to inform workplace strategies.</li>
                      <li>Projects that have enabled people to work from anywhere while access to systems is carefully monitored and managed.</li>
                      <li>Projects that promote and encourage data and cyber security awareness and understanding, giving teams the knowledge and information to remain vigilant in different work settings.</li>
                      <li>Projects that have accelerated the delivery of safer processes, systems and software to unlock new ways of working.</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
          </Row>
          <Row>
            <div className="col-md p-lg-4">
              <AwardCategory title="Customer Experience" gridColor="#d23464" icon="customer-experience">
                <AppModal openButton="Read More">
                  <AwardCategory title="Customer Experience" gridColor="#d23464" icon="customer-experience">
                    <p>Ultimately, public service revolves around the customer. A focus on customer experience builds trusting relationships and makes a workplace more attractive for staff.  </p>
                    <p>This award recognises organisations that have demonstrated excellence in fostering meaningful digital engagements and delivering a stellar customer experience. We invite entries that showcase innovative strategies, technologies, and initiatives aimed at creating positive and empowering relationships with customers.</p>
                    <p>Examples of entries could include (but are not limited to *):</p>
                    <ul>
                      <li>Projects that leverage emerging technologies, like virtual reality and augmented reality, to enhance customer experience.</li>
                      <li>Tools and solutions that support transparency and empower customers.</li>
                      <li>Creative use of digital channels to engage and connect with the public.</li>
                      <li>Implementation of customised and responsive customer service solutions.</li>
                      <li>Collaboration with customers to continuously refine and enhance experience based on real-user input.</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
            <div className="col-md p-lg-4">
              <AwardCategory title="User Experience" gridColor="#30c4bf" icon="user-experience">
                <AppModal openButton="Read More">
                  <AwardCategory title="User Experience" gridColor="#30c4bf" icon="user-experience">
                    <p>Smarter working can have a transformative impact on user experience, creating frictionless processes that help people to effectively navigate services.</p>
                    <p>We invite entries showcasing examples of organisations, teams or individuals who have harnessed the power of smart working to elevate employee experience whether through streamlined processes, intuitive interfaces, or innovative solutions.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Implementation of intuitive and user-friendly portals to enhance access to services.</li>
                      <li>Redesign of online forms and interfaces to optimise user engagement, ensuring a more straightforward and efficient user journey.</li>
                      <li>Implementation of personalised user profiles to tailor the digital experience to individuals' needs and preferences.</li>
                      <li>Integration of technological innovations like chatbots to assist users in accessing information and navigating complex processes seamlessly.</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
          </Row>
          <Row>
            <div className="col-md p-lg-4">
              <AwardCategory title="Smarter Workflows" gridColor="#30c4bf" icon="workflows">
                <AppModal openButton="Read More">
                  <AwardCategory title="Smarter Workflows" gridColor="#30c4bf" icon="workflows">
                    <p>Effective processes underpin exceptional delivery. When everything works seamlessly, they blend into the background, which means they're often overlooked. Simple and efficient workflows make life easier for both citizens and staff, helping enhance service delivery and turbocharge productivity.</p>
                    <p>This award spotlights the processes, platforms, hardware and design that make smarter working possible.</p>
                    <p>Examples of entries could include (but are not limited to *):</p>
                    <ul>
                      <li>Integration of Internet of Things devices to automate workflows.</li>
                      <li>Implementation of agile methodologies to enhance, flexibility, adaptability, and responsiveness.</li>
                      <li>The use of data analytics to identify bottlenecks and uncover innovative ways to optimise processes.</li>
                      <li>The integration of AI to intelligently route tasks, prioritise workloads and enhance efficiency.</li>
                      <li>Implementation of project management tools to enhance coordination and facilitate real-time collaboration across teams.</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
            <div className="col-md p-lg-4">
              <AwardCategory title="Digital Inclusion" gridColor="#d23464" icon="inclusion">
                <AppModal openButton="Read More">
                  <AwardCategory title="Digital Inclusion" gridColor="#d23464" icon="inclusion">
                    <p>While the rapid acceleration of digital technologies has enhanced services for many, it has also highlighted existing inequalities and has the potential to exclude people without access to technology or the skills and confidence to use it.  The public sector plays a crucial role in championing digital inclusion.</p>
                    <p>This award celebrates organisations and teams who have deployed Smarter Working technology to ensure equitable access for diverse audiences.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Projects that have championed digital inclusion with careful and considered planning.</li>
                      <li>Digital literacy training initiatives supporting citizens to build their confidence and skills in using new technologies.</li>
                      <li>The use of digital tools with innovative accessibility features to support people with different needs to access services.</li>
                      <li>Partnering with community groups and organisations to support people without access to technology at home.</li>
                      <li>The use of digital navigators to help those with limited digital literacy skills to navigate online services effectively.</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
          </Row>
          <Row>
            <div className="col-md p-lg-4">
              <AwardCategory title="Automation, Artificial Intelligence and Machine Learning" gridColor="#d23464" icon="brain">
                <AppModal openButton="Read More">
                  <AwardCategory title="Automation, Artificial Intelligence and Machine Learning" gridColor="#d23464" icon="brain">
                    <p>Automation, Artificial Intelligence (AI) and machine learning is the fastest growing technology in the world. And for good reason - when used effectively, the benefits are far-reaching - proving to boost effectiveness, free up staff time, reduce waste and produce significant cost-savings; it can inform decision-making and is a vital tool to drive maximum value in operations and service delivery.</p>
                    <p>This award recognises organisations who have implemented technology innovations to overcome challenges in managing large amounts of data or repetitive processes.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Projects which have prioritised innovation in technology to produce efficiency savings in productivity, costs or sustainability, with results being measured over time</li>
                      <li>Projects where automation, AI and machine learning has resolved long-standing issues or inefficiencies in an organisation or team</li>
                      <li>Projects where automation, AI and machine learning has opened opportunities for future technological advancements and ways of working</li>
                      <li>Projects that are enabling more rapid implementation of digitalisation, converting paper or digital images into data to power transformative technology</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
            <div className="col-md p-lg-4">
              <AwardCategory title="Building Collaborative Communities" gridColor="#30c4bf" icon="circle-people">
                <AppModal openButton="Read More">
                  <AwardCategory title="Building Collaborative Communities" gridColor="#30c4bf" icon="circle-people">
                    <p>Exemplary public sector services hinge on collaboration and communication. Great leaders understand the importance of listening to their people and drawing on their diverse skills, experience and insights.</p>
                    <p>This award is for organisations that have created the conditions to foster collaboration and communication – whether among staff or with customers.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Projects that have taken innovative approaches to unblock communication or collaboration barriers with demonstrable results.</li>
                      <li>Projects that have empowered staff to play an active role in changes to ways of working.</li>
                      <li>Projects where teams have trialled different initiatives to find the solution that best suits the needs of staff or users.</li>
                      <li>Projects that have engaged with local communities/groups to drive inclusion and local engagement in services.</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
          </Row>
          <Row>
            <div className="col-md p-lg-4">
              <AwardCategory title="Small-scale, Big Impact" gridColor="#30c4bf" icon="inward-arrows-square">
                <AppModal openButton="Read More">
                  <AwardCategory title="Small-scale, Big Impact" gridColor="#30c4bf" icon="inward-arrows-square">
                    <p>While budget constraints can be a limitation, they can also stimulate creativity. This award is for small-scale projects that have generated remarkable results. The impact of these projects could include supporting local communities, experimenting with green solutions or enhancing service delivery.</p>
                    <p>Outstanding teams will demonstrate how they turned the small-scale of the project to their advantage, encouraging imaginative solutions to overcome challenges.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Outstanding teams will demonstrate how they turned the small-scale of the project to their advantage, encouraging imaginative solutions to overcome challenges.</li>
                      <li>Projects that focused on transforming the quality of user or customer experience.</li>
                      <li>Projects where budget restrictions encouraged teams to think differently and achieve impressive results.</li>
                      <li>Strategic projects that involved thoughtful planning and were effectively managed to achieve objectives.</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
            <div className="col-md p-lg-4">
              <AwardCategory title="Putting People First" gridColor="#d23464" icon="inspire">
                <AppModal openButton="Read More">
                  <AwardCategory title="Putting People First" gridColor="#d23464" icon="inspire">
                    <p>Our wellbeing shapes how we feel about work and our lives. Promoting positive practices and proactively supporting people can help to minimise workplace stress and burnout. When people feel good at work, it brings both personal and organisational benefits and is linked to a higher quality of service.</p>
                    <p>This award is for projects that have championed workplace wellbeing and made a real difference to people’s mental health.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Projects that have prioritised mental health and wellbeing in the workspace or in service delivery, based on employee or user/customer feedback.</li>
                      <li>Projects that have helped to foster greater inclusivity and belonging in the workforce.</li>
                      <li>Projects that have contributed to attracting and retaining talent in the workforce.</li>
                      <li>Projects that have overcome challenges to enhance flexibility in how people work or receive services.</li>
                      <li>Projects that have involved the creation of support networks to help people protect their mental health.</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
          </Row>
          <Row>
            <div className="col-md p-lg-4">
              <AwardCategory title="Championing Continuous Improvement" gridColor="#d23464" icon="three-up-arrows">
                <AppModal openButton="Read More">
                  <AwardCategory title="Championing Continuous Improvement" gridColor="#d23464" icon="three-up-arrows">
                    <p>Change is most impactful when it becomes embedded within everyday practice. So often projects falter when a key influencer leaves or after the initial enthusiasm wanes and old habits creep in. Continuous improvement approaches are essential to ensure initiatives have a long-term impact.</p>
                    <p>This award is for organisations that have thoughtfully factored in continuous improvement methodologies to ensure sustained adoption.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Projects that have encouraged staff to take ownership of the local spread of innovations with test-and-learn approaches.</li>
                      <li>Projects that have embedded smarter working into wider organisational strategies to ensure it becomes part of day-to-day activities.</li>
                      <li>Projects that continually seek out and act on employee feedback to identify and address development needs.</li>
                      <li>Projects that have focused on changing habits and cultures to sustain new working practices.</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
            <div className="col-md p-lg-4">
              <AwardCategory title="Outstanding Smarter Working Leadership" gridColor="#30c4bf" icon="leader">
                <AppModal openButton="Read More">
                  <AwardCategory title="Outstanding Smarter Working Leadership" gridColor="#30c4bf" icon="leader">
                    <p>Smarter working involves cultural change. Leaders must work collaboratively to come up with creative solutions, build trust and drive innovation.</p>
                    <p>This award is for organisations, teams or individuals that role-modelled the principles of smarter working while empowering staff or customers to transform ways of working.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Organisational leadership where leaders (at any level of the organisation) champion smarter working strategy and help to inspire a growing movement.</li>
                      <li>Projects that have fostered a culture where positive behaviours are encouraged, recognised and rewarded.</li>
                      <li>Projects that have an active champions' network assuming local leadership roles.</li>
                      <li>Projects that help leaders develop the mindset and tools to deliver smarter working outcomes.</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
          </Row>
        </Container>

        <Container>
          <br /><br /><br /><br />
          <Container>
            <div className="row" style={{
              position: `relative`
            }}>
              <div className="col-lg" style={{
                background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                padding: `3rem`
              }}>
                <div style={{ margin: `0 1.1rem`}}>
                  <h4 style={{
                    display: `inline`,
                    backgroundColor: `#d23464`,
                    padding: `.5rem 0`,
                    lineHeight: `3.4rem`,
                    boxShadow: `1.2rem 0 0 #d23464, -1.2rem 0 0 #d23464`,
                    textTransform: `uppercase`,
                    color: `#fff`,
                    fontFamily: `Avenir`,
                    fontWeight: `900`
                  }}>Judges Choice</h4><br />
                  <h4 hidden style={{
                    display: `inline`,
                    backgroundColor: `#d23464`,
                    padding: `.5rem 0`,
                    lineHeight: `3.4rem`,
                    boxShadow: `1.2rem 0 0 #d23464, -1.2rem 0 0 #d23464`,
                    textTransform: `uppercase`,
                    color: `#fff`,
                    fontFamily: `Avenir`,
                    fontWeight: `900`
                  }}><small>The Judges Choice</small></h4>
                </div>
                <p style={{
                  color: `#fff`,
                  fontFamily: `Din Pro`,
                  marginTop: `1.5rem`
                }}>Below are the Judges Choice Awards. These are different from the awards mentioned above, with one main distinction: your organisation or team cannot submit an entry to be considered for the Judges Choice awards - they can only be nominated by our expert judges.</p>
                <p style={{
                  color: `#fff`,
                  fontFamily: `Din Pro`,
                  marginTop: `1.5rem`
                }}>
                For your entry to the main award categories to be considered for the Judges Choice awards, before submitting we recommend that you review the criteria which the judges will use to assess entries. This will increase your chances of making the Judges Choice awards shortlist.</p>
              </div>
              <PlacesBackgroundImage className="col-lg">
                <div style={{
                  background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                  width: `10rem`,
                  height: `10rem`,
                  borderRadius: `50%`,
                  position: `absolute`,
                  bottom: `1.5rem`,
                  right: `-5rem`
                }} />
                <BandsCircle style={{ position: `absolute`, top: `50%`, right: `-16rem`, marginTop: `-9rem`}} size="20rem" />
                <BandsDiamond type="fine" style={{ position: `absolute`, left: `-2.5rem`, top: `-2.5rem`}} />
              </PlacesBackgroundImage>
            </div>
          </Container>
          <br /><br />
          <Container>
            <Row>
              <div className="col-md p-lg-4">
                <AwardCategory title="Special Recognition" gridColor="#30c4bf" icon="star">
                  <AppModal openButton="Read More">
                    <AwardCategory title="Special Recognition" gridColor="#30c4bf" icon="star">
                      <p>The Special Recognition award is reserved for trailblazing organisations and teams who have gone above and beyond in their dedication to smarter working. This award celebrates projects with a truly ambitious vision – whether that’s groundbreaking digital innovation, bringing together staff and communities, or pioneering new strategies to reach carbon net zero. Inevitably, there will have been challenges and lessons learned, but teams ultimately remained steadfast in their commitment to inspiring change and transforming services.</p>
                      <strong style={{ color: `rgb(210, 52, 100)` }}><em>Please note: Organisations cannot apply to this award and can only be put forward for consideration by Smarter Working Live judges.</em></strong>
                    </AwardCategory>
                  </AppModal>
                </AwardCategory>
              </div>
              <div className="col-md p-lg-4">
                <AwardCategory title="Beyond Smarter Working" gridColor="#d23464" icon="light-bulb-cog">
                  <AppModal openButton="Read More">
                    <AwardCategory title="Beyond Smarter Working" gridColor="#d23464" icon="light-bulb-cog">
                      <p>This prestigious and long-standing award is a combination of all the Smarter Working Live awards. It is to celebrate projects that combine people, places, or technology in true smarter working excellence. We are looking for projects that have shown bold ambition, innovation, and cultural change; where thoughtful planning and collaboration have inspired new ways of working; where innovative strategies have driven efficiencies or sustainability; where improved outcomes exceeded expectations and inspired the spread of further Smarter Working led projects. This award is a tribute to projects that have achieved meaningful, long-term change.</p>
                      <strong style={{ color: `rgb(210, 52, 100)` }}><em>Please note: Organisations cannot apply to this award and can only be put forward for consideration by Smarter Working Live judges.</em></strong>
                    </AwardCategory>
                  </AppModal>
                </AwardCategory>
              </div>
            </Row>
            {/* <Row>
              <div className="col-md p-lg-4">
                <AwardCategory title="Smarter Working Maturity" gridColor="#d23464" icon="computer-desk">
                <p>need icon</p>
                </AwardCategory>
              </div>
            </Row> */}
          </Container>
        </Container>
      </Container>
      <br />
      <br />
      <br />
    </Layout>
  </Background>
)

export default CategoriesPage
